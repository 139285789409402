import React, { useState, useEffect, useMemo } from "react"
import { FcCloseUpMode } from "react-icons/fc"
import Layout from "../../components/Layout"
import SEO from "../../components/SEO/SEO"
import SeeAlso from "../../components/SeeAlso/SeeAlso"

import NavBar from "../../components/NavBar/NavBar"
import { isSSR } from "../../components/Script/script"
import {
  Title,
  SubTitle,
  Columns,
  Column,
  Button,
} from "../../StyleComponents/styles"
import { FlexDiv } from "../../StyleComponents/pagesStyle"

const GoogleAds = React.lazy(() => import("../../components/ADS/ADS"))

function FlowersGenerator(props) {
  const { node } = props.pageContext
  let [result1, setresult1] = useState([])
  let [hide, setHide] = useState(false)

  useEffect(() => {
    generate()
  }, [])

  const generate = () => {
    randomizer(node.flowersName)
  }

  const randomizer = list => {
    let NamesData = []
    for (let i = 0; i < 7; i++) {
      let random = Math.floor(Math.random() * list.length)
      NamesData.push({
        name: list[random].name,
        href: list[random].href,
      })
    }
    setresult1(NamesData)
    if (hide === false) {
      setHide(true)
    }
  }

  // const copyName = e => {
  //   console.log(e.target)
  // }
  return (
    <Layout location={props.location}>
      <SEO
        title={`Random ${node.name} Generator`}
        description={`Simply press on the button "generate" to generate a random ${node.name}, wee help you to find the perfect ${node.name}.`}
        keywords={[
          `${node.name},Flowers Name generator,Flower name generator, random Flowers name generator, random Flowers name,make Flowers name, random ${node.name},random Flowers`,
        ]}
      />
      <NavBar
        listPages={useMemo(() => {
          return [
            { name: "Generator", link: "/generator/" },
            { name: `${node.name} Generator`, link: `/${node.path}/` },
          ]
        }, [node.name, node.path])}
      />
      <section className="container">
        <Title>{node.name} generator</Title>
        <SubTitle>Random {node.name} Generator</SubTitle>
        <br />
        <Columns>
          <Column>
            <FlexDiv textAlign="center" minHeight="400px">
              <Button
                className="mb-3"
                borderColor="#1da1f2"
                color="#1da1f2"
                hoverColor="white"
                hoverBorderColor="#1da1f2"
                hoverBackgroundColor="#1da1f2"
                fontSize="1.5rem"
                value="Generate"
                onClick={generate}
              >
                Generate
              </Button>

              <br />

              {hide ? (
                <div>
                  {result1.map((e, index) => {
                    return (
                      <p className="h5" key={index}>
                        {e.name}{" "}
                        <a
                          className="ml-4"
                          key={index + "h"}
                          href={e.href}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span>&#8505;</span>
                        </a>
                      </p>
                    )
                  })}
                </div>
              ) : (
                ""
              )}
            </FlexDiv>
          </Column>
          <Column>
            {/* google ads UnitPediaRight */}
            {!isSSR && (
              <React.Suspense fallback={<div />}>
                <GoogleAds
                  slot="1189740279"
                  data-ad-format="auto"
                  data-ad-layout-key="-gi+g+7v-1q-6r"
                  data-full-width-responsive="true"
                />
              </React.Suspense>
            )}
          </Column>
        </Columns>
        <br />
        <FlexDiv maxWidth="250px" margin="auto">
          <FcCloseUpMode size={82} />
        </FlexDiv>
        <br />
        <div>
          <h3>Random {node.name} Generator</h3>
          <p>
            Simply press on the button "generate" to generate a random{" "}
            {node.name}, wee help you to find the perfect {node.name}
          </p>
        </div>
        <br />
        <SeeAlso seeAlsoList={node.SeeAlso} />
        <br />
      </section>
    </Layout>
  )
}

export default FlowersGenerator
